@import url("https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap");

:root {
  --primary-color: #00549a;
}

* {
  box-sizing: border-box;
  font-family: "Zain", sans-serif;
}

body {
  margin: 0;
  font-family: "Zain", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.offcanvas-header .btn-close {
  margin: 0 !important;
}
.nav-link.active {
  color: var(--primary-color) !important;
  position: relative !important;
  transition: 3ms;
  -webkit-transition: 3ms;
  -moz-transition: 3ms;
  -ms-transition: 3ms;
  -o-transition: 3ms;
  width: fit-content;
}
.nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 2px;
  background: var(--primary-color);
  transition: 3ms;
  -webkit-transition: 3ms;
  -moz-transition: 3ms;
  -ms-transition: 3ms;
  -o-transition: 3ms;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}
/* Hero */

@media (min-width: 576px) {
  .hero-text {
    font-size: 36px !important;
  }
}

@media (min-width: 768px) {
  .hero-text {
    font-size: 50px !important;
  }
}

@media (min-width: 992px) {
  .hero-text {
    font-size: 75px !important;
  }
}

@media (min-width: 1200px) {
}

.truncate-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
